import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/Luis/Documents (syncthing)/Projects/Jacqueline Website/jacqueline-website/theme/src/components/common/Layout/index.jsx";
import SEO from 'components/common/SEO';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="404: Not found" location="/404" mdxType="SEO" />
    <h1>{`NOT FOUND`}</h1>
    <p>{`You just hit a route that doesn`}{`'`}{`t exist... the sadness.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      